import React, { useState, useEffect } from 'react';

function Cursor() {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [cursorVisible, setCursorVisible] = useState(false);

  const updateCursorPosition = (event) => {
    const { clientX: x, clientY: y } = event;
    setCursorPosition({ x, y });

    if (!cursorVisible) {
      setCursorVisible(true);
    }
  };

  const handleCursorOutline = () => {
    document.querySelector('.cursor').classList.add('hovering');
  };

  const removeCursorOutline = () => {
    document.querySelector('.cursor').classList.remove('hovering');
  };

  const handleWhiteBackgroundHover = () => {
    document.querySelector('.cursor').classList.add('invert');
  };

  const handleWhiteBackgroundLeave = () => {
    document.querySelector('.cursor').classList.remove('invert');
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateCursorPosition);
    const anchorTags = document.querySelectorAll('a');
    const buttons = document.querySelectorAll('button');
    
    anchorTags.forEach((anchor) => {
      anchor.addEventListener('mouseenter', handleCursorOutline);
      anchor.addEventListener('mouseleave', removeCursorOutline);
    });

    buttons.forEach((button) => {
      button.addEventListener('mouseenter', handleCursorOutline);
      button.addEventListener('mouseleave', removeCursorOutline);
    });
  
    const setupElementListeners = () => {
      const banner = document.querySelector('#banner');
      const secondHeader = document.querySelector('.secondHeader');
      const howItWorksSection = document.querySelector('.howItWorks');
      const exploreSection = document.querySelector('.explore');
      const archiveSection = document.querySelector('.archive');
      const faqSection = document.querySelector('.faq');
      const teamSection = document.querySelector('.team');

      if (banner && secondHeader) {
        banner.addEventListener('mouseenter', handleWhiteBackgroundHover);
        banner.addEventListener('mouseleave', handleWhiteBackgroundLeave);
    
        secondHeader.addEventListener('mouseenter', handleWhiteBackgroundHover);
        secondHeader.addEventListener('mouseleave', handleWhiteBackgroundLeave);

        howItWorksSection.addEventListener('mouseenter', handleWhiteBackgroundHover);
        howItWorksSection.addEventListener('mouseleave', handleWhiteBackgroundLeave);

        exploreSection.addEventListener('mouseenter', handleWhiteBackgroundHover);
        exploreSection.addEventListener('mouseleave', handleWhiteBackgroundLeave);

        archiveSection.addEventListener('mouseenter', handleWhiteBackgroundHover);
        archiveSection.addEventListener('mouseleave', handleWhiteBackgroundLeave);

        faqSection.addEventListener('mouseenter', handleWhiteBackgroundHover);
        faqSection.addEventListener('mouseleave', handleWhiteBackgroundLeave);

        teamSection.addEventListener('mouseenter', handleWhiteBackgroundHover);
        teamSection.addEventListener('mouseleave', handleWhiteBackgroundLeave);
      }
    };
  
    setupElementListeners();
  
    return () => {
      window.removeEventListener('mousemove', updateCursorPosition);
      anchorTags.forEach((anchor) => {
        anchor.addEventListener('mouseenter', handleCursorOutline);
        anchor.addEventListener('mouseleave', removeCursorOutline);
      });
  
      buttons.forEach((button) => {
        button.addEventListener('mouseenter', handleCursorOutline);
        button.addEventListener('mouseleave', removeCursorOutline);
      });
  
      setupElementListeners(); 
    };
  }, [cursorVisible]);
  

  return (
    <div
      className={`cursor ${cursorVisible ? 'visible' : ''}`}
      style={{ left: cursorPosition.x, top: cursorPosition.y }}
    ></div>
  );
}

export default Cursor;
