import React, { useState, useEffect } from 'react';
import LandingImage from '../assets/imagery/landingImage.webp';
import SelectorsGraffiti from '../assets/logos/selectorsGraffitiWhite.webp';

function Landing() {
  const [logoVisible, setLogoVisible] = useState(false);
  const [landingImageLoaded, setLandingImageLoaded] = useState(false);
  const [overlayLighter, setOverlayLighter] = useState(false); // State for adding the 'lighter' class to overlay

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLogoVisible(true);
    }, 900);

    const preloadImage = new Image();
    preloadImage.src = LandingImage;
    preloadImage.onload = () => {
      setLandingImageLoaded(true);
    };

    const overlayTimeout = setTimeout(() => {
      setOverlayLighter(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
      clearTimeout(overlayTimeout);
    };
  }, []);

  return (
    <div>
      <div className='landing one' id='landingComponent'>
        {landingImageLoaded && (

          <div className='imageContainer'>
            <img
              width="1228"
              height="852"
              className={`landingImage ${logoVisible ? 'zoom-out' : ''}`}
              src={LandingImage}
              alt='landing image'
            />
        </div>
        )}
        <div className={`overlay ${overlayLighter ? 'lighter' : ''}`}></div>
        <div className={`logoContainer ${logoVisible ? 'fade-in' : ''}`}>
          <div className='landingContainer'>
            <img className='logo' src={SelectorsGraffiti} alt='selectors graffiti logo white' width="478" height="198" />
            <div className='sloganContainer'>
              <p>REMIXING <span>MUSIC DISCOVERY</span> & <span>LIFESTYLE</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Landing;
