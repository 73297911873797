import React, { useState, useEffect } from 'react';
import SelectorsWhite from '../assets/logos/selectorsWhite.webp';
import SelectorsOrange from '../assets/logos/selectorsOrange.webp';

function Header() {
  const [shouldFadeOut, setShouldFadeOut] = useState(false);
  const [shouldFadeInSecondHeader, setShouldFadeInSecondHeader] = useState(false);
  const [logoVisible, setLogoVisible] = useState(false);
  const [lineVisible, setLineVisible] = useState(false);
  const [roadmapVisible, setRoadmapVisible] = useState(false);
  const [exploreVisible, setExploreVisible] = useState(false);
  const [archiveVisible, setArchiveVisible] = useState(false);
  const [fanzineVisible, setFanzineVisible] = useState(false);
  const [logoHovered, setLogoHovered] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  useEffect(() => {
    return () => {
      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
      }
    };
  }, [hoverTimeout]);

  const handleMouseEnter = () => {
    const timeout = setTimeout(() => {
      setLogoHovered(true);
    }, 150);

    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    setLogoHovered(false);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const viewportHeight = window.innerHeight;
  
    if (scrollPosition >= viewportHeight * 0.2) {
      setShouldFadeOut(true);
    } else {
      setShouldFadeOut(false);
    }
  
    if (scrollPosition >= viewportHeight - 86) {
      setShouldFadeInSecondHeader(true);
    } else {
      setShouldFadeInSecondHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const timeout = setTimeout(() => {
      setLogoVisible(true);
    }, 200);
    const timeout2 = setTimeout(() => {
      setLineVisible(true);
    }, 400);
    const timeout3 = setTimeout(() => {
      setRoadmapVisible(true);
    }, 600);
    const timeout4 = setTimeout(() => {
      setExploreVisible(true);
    }, 800);
    const timeout5 = setTimeout(() => {
      setArchiveVisible(true);
    }, 1000);
    const timeout6 = setTimeout(() => {
      setFanzineVisible(true);
    }, 1200);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeout);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
      clearTimeout(timeout4);
      clearTimeout(timeout5);
      clearTimeout(timeout6);
    };
  }, []);

  return (
    <div className='headerContainer'>
      <div className={`header ${shouldFadeOut ? 'fade-out' : ''}`}>
        <a className='mintBanner' href='https://mint.cnft.tools/selectors' target='_blank'>Mint is live! Get your Selectors character here.</a>
        <div className='headerLeft'>
          <a href="/" className={`logoContainer ${logoVisible ? 'fade-in' : ''}`} aria-label="Redirect back to the root of the site" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img width="50" height="50" className={`logo ${logoHovered ? 'hovered' : ''}`} src={logoHovered ? SelectorsOrange : SelectorsWhite} alt="selectors logo"/>
          </a>
          <div className={`linkContainer ${lineVisible ? 'fade-in' : ''}`}>
            <a className={`link ${roadmapVisible ? 'fade-in' : ''}`} href='/timeline' aria-label='Find out more about our roadmap and future plans'>
            <svg className='icon' version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="99.000000pt" height="99.000000pt" viewBox="0 0 99.000000 99.000000"
            preserveAspectRatio="xMidYMid meet">
              <g className='fill' transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)"
              stroke="none">
              <path d="M79 921 l-24 -19 -3 -384 c-1 -210 0 -393 3 -406 3 -12 14 -31 25
              -42 19 -19 35 -20 418 -20 218 0 402 4 408 8 27 18 34 55 33 179 l-2 128 -8
              -129 c-5 -71 -11 -131 -14 -134 -3 -4 -97 88 -209 203 l-202 209 195 119 c108
              66 201 124 206 130 13 13 26 20 -223 -118 -116 -64 -215 -121 -217 -126 -3 -5
              87 -104 201 -221 l206 -213 -48 -3 -48 -3 -182 182 c-101 101 -185 181 -188
              178 -8 -8 -68 -300 -63 -305 2 -3 17 46 32 108 16 62 32 123 36 135 8 21 22 9
              164 -139 85 -89 154 -162 153 -162 -2 -1 -142 1 -313 4 l-309 5 -13 25 c-15
              29 -17 108 -4 143 6 16 42 44 96 77 47 29 88 50 90 48 2 -2 -7 -53 -21 -113
              -35 -160 -28 -160 16 0 20 77 34 143 30 148 -3 4 -50 -17 -104 -48 -54 -30
              -100 -55 -102 -55 -2 0 -4 19 -4 43 l0 42 103 59 c153 89 154 90 166 145 6 27
              24 108 41 180 l32 131 37 0 38 0 -28 -122 c-31 -134 -34 -168 -19 -168 11 0
              419 246 435 263 10 10 -19 -6 -265 -142 -79 -44 -145 -79 -147 -78 -1 1 12 57
              29 123 l30 121 66 7 c37 3 113 6 168 6 143 0 143 0 151 -160 3 -69 7 -127 9
              -129 7 -7 4 246 -2 264 -17 42 -44 45 -447 45 -364 0 -386 -1 -408 -19z m310
              -28 c0 -10 -17 -88 -38 -173 l-37 -155 -107 -63 c-60 -35 -110 -62 -112 -61
              -1 2 -5 101 -6 220 -3 172 -1 219 10 232 11 14 35 17 152 17 126 0 139 -2 138
              -17z"/>
              <path d="M221 104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
              <path d="M331 104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
              </g>
            </svg>
              <p>timeline</p>
              <div className='circle'>
                <svg className='exclaimation' xmlns="http://www.w3.org/2000/svg"  version="1.1" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 100 125"><g id="a"><path fill="#ffffff" stroke="none" d=" M 53.65 73.3 Q 52.75 72.9 51.7 72.75 50.9 72.6 50 72.6 49.15 72.6 48.35 72.75 47.3 72.9 46.4 73.3 44.8 73.9 43.5 75.25 40.8 78 40.8 81.85 40.8 85.7 43.5 88.4 46.2 91.1 50 91.1 53.85 91.1 56.55 88.4 59.25 85.7 59.25 81.85 59.25 78 56.55 75.25 55.25 73.9 53.65 73.3 M 62.9 23.15 Q 64.45 10.05 50 10.15 35.6 10.2 37.25 22.95 L 43.85 68.35 56.3 68.35 62.9 23.15 Z"/></g></svg>
              </div>
            </a>
            <a className={`link ${roadmapVisible ? 'fade-in' : ''}`} href='/roadmap' aria-label='Find out more about our roadmap and future plans'>
            <svg className='icon' width="800px" height="800px" viewBox="0 0 32 32" id="icon">
              <defs>
              </defs>
              <path fill='white' d="M12,30H4a2.0023,2.0023,0,0,1-2-2V24a2.0023,2.0023,0,0,1,2-2h8a2.0023,2.0023,0,0,1,2,2v4A2.0023,2.0023,0,0,1,12,30ZM4,24v4h8V24Z"/>
              <path fill='white' d="M28,20H12a2.0023,2.0023,0,0,1-2-2V14a2.0023,2.0023,0,0,1,2-2H28a2.0023,2.0023,0,0,1,2,2v4A2.0023,2.0023,0,0,1,28,20ZM12,14v4H28V14Z"/>
              <path fill='white' d="M16,10H4A2.0023,2.0023,0,0,1,2,8V4A2.0023,2.0023,0,0,1,4,2H16a2.0023,2.0023,0,0,1,2,2V8A2.0023,2.0023,0,0,1,16,10ZM4,4V8H16V4Z"/>
            </svg>
              <p>roadmap</p>
            </a>
          </div>
        </div>
        <div className='socialContainer'>
          <a className={`socialLink ${archiveVisible ? 'fade-in' : ''}`} href='/' aria-label='Join our discord community and get involved!' target='_blank'>
          <svg className='icon bigger' fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1"
            viewBox="0 0 300 300">
          <g id="XMLID_526_">
            <path className="fill" id="XMLID_527_" d="M14.492,208.896c0.619,0,1.143-0.509,1.232-1.226l3.365-26.671l-3.355-27.278
              c-0.1-0.717-0.623-1.23-1.242-1.23c-0.635,0-1.176,0.524-1.26,1.23l-2.941,27.278l2.941,26.662
              C13.316,208.377,13.857,208.896,14.492,208.896z"/>
            <path className="fill" id="XMLID_530_" d="M3.397,198.752c0.608,0,1.101-0.473,1.19-1.18l2.608-16.574l-2.608-16.884
              c-0.09-0.685-0.582-1.18-1.19-1.18c-0.635,0-1.127,0.495-1.217,1.19L0,180.999l2.18,16.569
              C2.27,198.269,2.762,198.752,3.397,198.752z"/>
            <path className="fill" id="XMLID_531_" d="M27.762,148.644c-0.08-0.867-0.715-1.5-1.503-1.5c-0.782,0-1.418,0.633-1.491,1.5l-2.811,32.355
              l2.811,31.174c0.073,0.862,0.709,1.487,1.491,1.487c0.788,0,1.423-0.625,1.503-1.487l3.18-31.174L27.762,148.644z"/>
            <path className="fill" id="XMLID_532_" d="M38.152,214.916c0.922,0,1.668-0.759,1.758-1.751l3.005-32.156l-3.005-33.258
              c-0.09-0.999-0.836-1.749-1.758-1.749c-0.935,0-1.692,0.751-1.756,1.754l-2.656,33.253l2.656,32.156
              C36.46,214.158,37.217,214.916,38.152,214.916z"/>
            <path className="fill" id="XMLID_533_" d="M50.127,215.438c1.074,0,1.936-0.86,2.025-2.011l-0.01,0.008l2.83-32.426l-2.83-30.857
              c-0.08-1.132-0.941-2.005-2.016-2.005c-1.09,0-1.947,0.873-2.012,2.014l-2.502,30.849l2.502,32.418
              C48.18,214.578,49.037,215.438,50.127,215.438z"/>
            <path className="fill" id="XMLID_534_" d="M67.132,181.017l-2.655-50.172c-0.074-1.272-1.065-2.286-2.281-2.286c-1.207,0-2.195,1.013-2.269,2.286
              l-2.35,50.172l2.35,32.418c0.074,1.278,1.063,2.278,2.269,2.278c1.217,0,2.207-1,2.281-2.278v0.009L67.132,181.017z"/>
            <path className="fill" id="XMLID_535_" d="M74.386,215.766c1.339,0,2.45-1.111,2.513-2.529v0.021l2.482-32.233l-2.482-61.656
              c-0.063-1.418-1.174-2.529-2.513-2.529c-1.37,0-2.471,1.111-2.545,2.529l-2.185,61.656l2.195,32.222
              C71.915,214.655,73.016,215.766,74.386,215.766z"/>
            <path className="fill" id="XMLID_536_" d="M86.645,111.435c-1.508,0-2.725,1.238-2.787,2.799l-2.033,66.801l2.033,31.884
              c0.063,1.553,1.279,2.783,2.787,2.783c1.504,0,2.73-1.22,2.783-2.788v0.016l2.307-31.895l-2.307-66.801
              C89.375,112.663,88.148,111.435,86.645,111.435z"/>
            <path className="fill" id="XMLID_782_" d="M99.01,215.766c1.656,0,2.975-1.336,3.037-3.056v0.019l2.133-31.693l-2.133-69.045
              c-0.063-1.714-1.381-3.056-3.037-3.056c-1.666,0-3.005,1.342-3.031,3.056l-1.916,69.045l1.916,31.693
              C96.005,214.43,97.344,215.766,99.01,215.766z"/>
            <path className="fill" id="XMLID_783_" d="M111.477,215.734c1.787,0,3.237-1.463,3.291-3.318v0.029l1.963-31.404l-1.963-67.289
              c-0.054-1.854-1.504-3.311-3.291-3.311c-1.8,0-3.25,1.456-3.303,3.311l-1.725,67.289l1.736,31.389
              C108.227,214.271,109.677,215.734,111.477,215.734z"/>
            <path className="fill" id="XMLID_784_" d="M129.359,181.041l-1.777-64.836c-0.043-2-1.609-3.571-3.551-3.571c-1.947,0-3.514,1.571-3.555,3.584
              l-1.594,64.823l1.594,31.198c0.041,1.984,1.607,3.556,3.555,3.556c1.941,0,3.508-1.572,3.551-3.585v0.029L129.359,181.041z"/>
            <path className="fill" id="XMLID_785_" d="M136.682,215.853c2.064,0,3.773-1.717,3.805-3.828v0.017l1.613-30.984l-1.613-77.153
              c-0.031-2.119-1.74-3.833-3.805-3.833c-2.063,0-3.767,1.722-3.809,3.844l-1.434,77.111l1.434,31.016
              C132.915,214.136,134.619,215.853,136.682,215.853z"/>
            <path className="fill" id="XMLID_786_" d="M149.291,92.814c-2.229,0-4.037,1.849-4.074,4.103l-1.667,84.151l1.677,30.526
              c0.027,2.225,1.836,4.068,4.064,4.068c2.195,0,4.037-1.844,4.047-4.105v0.037l1.82-30.526l-1.82-84.151
              C153.328,94.655,151.486,92.814,149.291,92.814z"/>
            <path className="fill" id="XMLID_787_" d="M160.82,215.882c0.09,0.008,101.623,0.056,102.275,0.056c20.385,0,36.904-16.722,36.904-37.357
              c0-20.624-16.52-37.349-36.904-37.349c-5.059,0-9.879,1.034-14.275,2.907c-2.922-33.671-30.815-60.077-64.842-60.077
              c-8.318,0-16.429,1.662-23.593,4.469c-2.788,1.09-3.534,2.214-3.556,4.392v118.539C156.861,213.752,158.607,215.655,160.82,215.882
              z"/>
          </g>
          </svg>
          </a>
          <a className={`socialLink ${exploreVisible ? 'fade-in' : ''}`} href='https://x.com/Selectors_' aria-label='Give us a follow on X to stay in the loop regarding the latest Selectors news!' target='_blank'>
            <svg className='icon' width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" alt='x icon'>
              <path className='fill' d="M0.0682668 0L10.8788 14.3405L0 26H2.44837L11.9727 15.792L19.6681 26H28L16.5813 10.8528L26.7071 0H24.2588L15.4873 9.4014L8.40017 0H0.0682668ZM3.66878 1.78924H7.49647L24.3989 24.2105H20.5712L3.66878 1.78924Z"/>
            </svg>
          </a>
          <a className={`socialLink ${roadmapVisible ? 'fade-in' : ''}`} href='/' aria-label='Check out our Spotify playlists!' target='_blank'>
          <svg className='icon' width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt='spotify icon'>
            <path className='fill' fillRule="evenodd" clipRule="evenodd" d="M23.8725 13.2975C19.0375 10.4262 11.0625 10.1625 6.44625 11.5638C5.705 11.7888 4.92125 11.37 4.6975 10.6288C4.4725 9.8875 4.89 9.10375 5.6325 8.87875C10.9312 7.27 19.7387 7.58125 25.305 10.885C25.9712 11.2813 26.19 12.1413 25.795 12.8075C25.4 13.4738 24.5387 13.6937 23.8725 13.2975ZM23.715 17.5513C23.375 18.1013 22.6562 18.2737 22.1062 17.9362C18.075 15.4587 11.9288 14.74 7.15875 16.1875C6.54125 16.375 5.8875 16.0262 5.7 15.4087C5.51375 14.79 5.8625 14.1387 6.48 13.95C11.9275 12.2975 18.7 13.0975 23.33 15.9438C23.88 16.2813 24.0525 17.0025 23.715 17.5513ZM21.8788 21.635C21.61 22.0775 21.0338 22.2162 20.5938 21.9462C17.0713 19.7937 12.6375 19.3075 7.41625 20.5C6.91375 20.615 6.4125 20.3 6.2975 19.7975C6.1825 19.2938 6.49625 18.7925 7 18.6775C12.7138 17.3713 17.615 17.9338 21.5687 20.35C22.01 20.6188 22.1488 21.195 21.8788 21.635ZM15 0C6.71625 0 0 6.71625 0 15C0 23.285 6.71625 30 15 30C23.285 30 30 23.285 30 15C30 6.71625 23.285 0 15 0Z"/>
          </svg>
          </a>
          <a className={`socialLink ${roadmapVisible ? 'fade-in' : ''}`} href='https://discord.gg/GYNeUYCnxX' aria-label='Check out our Discord' target='_blank'>
          <svg width="128" height="97" viewBox="0 0 128 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M107.7 8.07C99.3467 4.24568 90.5282 1.53258 81.4699 0C80.2304 2.21588 79.1088 4.49573 78.1099 6.83C68.461 5.37602 58.6488 5.37602 48.9999 6.83C48.0005 4.49597 46.8789 2.21615 45.6399 0C36.5758 1.54552 27.7515 4.26506 19.3899 8.09C2.7899 32.65 -1.7101 56.6 0.539904 80.21C10.2613 87.3926 21.1423 92.8551 32.7099 96.36C35.3146 92.8568 37.6194 89.1403 39.5999 85.25C35.8382 83.8451 32.2076 82.1117 28.7499 80.07C29.6599 79.41 30.5499 78.73 31.4099 78.07C41.4709 82.8014 52.4519 85.2546 63.5699 85.2546C74.6879 85.2546 85.6689 82.8014 95.7299 78.07C96.5999 78.78 97.4899 79.46 98.3899 80.07C94.9256 82.115 91.2882 83.8517 87.5199 85.26C89.498 89.1485 91.8029 92.8619 94.4099 96.36C105.987 92.8691 116.877 87.4092 126.6 80.22C129.24 52.84 122.09 29.11 107.7 8.07ZM42.4499 65.69C36.1799 65.69 30.9999 60 30.9999 53C30.9999 46 35.9999 40.26 42.4299 40.26C48.8599 40.26 53.9999 46 53.8899 53C53.7799 60 48.8399 65.69 42.4499 65.69ZM84.6899 65.69C78.4099 65.69 73.2499 60 73.2499 53C73.2499 46 78.2499 40.26 84.6899 40.26C91.1299 40.26 96.2299 46 96.1199 53C96.0099 60 91.0799 65.69 84.6899 65.69Z" fill="white"/>
          </svg>
          </a>
        </div>
        <div className='mobile'>
          <nav role='navigation'>
		        <div id="menuToggle">
			        <input type="checkbox" />
			        <span></span>
			        <span></span>
			        <span className='last'></span>
			        <div id="menu">
              <a className={`link ${roadmapVisible ? 'fade-in' : ''}`} href='/timeline' aria-label='Find out more about our roadmap and future plans'>
            <svg className='icon' version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="99.000000pt" height="99.000000pt" viewBox="0 0 99.000000 99.000000"
            preserveAspectRatio="xMidYMid meet">
              <g className='fill' transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)"
              stroke="none">
              <path d="M79 921 l-24 -19 -3 -384 c-1 -210 0 -393 3 -406 3 -12 14 -31 25
              -42 19 -19 35 -20 418 -20 218 0 402 4 408 8 27 18 34 55 33 179 l-2 128 -8
              -129 c-5 -71 -11 -131 -14 -134 -3 -4 -97 88 -209 203 l-202 209 195 119 c108
              66 201 124 206 130 13 13 26 20 -223 -118 -116 -64 -215 -121 -217 -126 -3 -5
              87 -104 201 -221 l206 -213 -48 -3 -48 -3 -182 182 c-101 101 -185 181 -188
              178 -8 -8 -68 -300 -63 -305 2 -3 17 46 32 108 16 62 32 123 36 135 8 21 22 9
              164 -139 85 -89 154 -162 153 -162 -2 -1 -142 1 -313 4 l-309 5 -13 25 c-15
              29 -17 108 -4 143 6 16 42 44 96 77 47 29 88 50 90 48 2 -2 -7 -53 -21 -113
              -35 -160 -28 -160 16 0 20 77 34 143 30 148 -3 4 -50 -17 -104 -48 -54 -30
              -100 -55 -102 -55 -2 0 -4 19 -4 43 l0 42 103 59 c153 89 154 90 166 145 6 27
              24 108 41 180 l32 131 37 0 38 0 -28 -122 c-31 -134 -34 -168 -19 -168 11 0
              419 246 435 263 10 10 -19 -6 -265 -142 -79 -44 -145 -79 -147 -78 -1 1 12 57
              29 123 l30 121 66 7 c37 3 113 6 168 6 143 0 143 0 151 -160 3 -69 7 -127 9
              -129 7 -7 4 246 -2 264 -17 42 -44 45 -447 45 -364 0 -386 -1 -408 -19z m310
              -28 c0 -10 -17 -88 -38 -173 l-37 -155 -107 -63 c-60 -35 -110 -62 -112 -61
              -1 2 -5 101 -6 220 -3 172 -1 219 10 232 11 14 35 17 152 17 126 0 139 -2 138
              -17z"/>
              <path d="M221 104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
              <path d="M331 104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
              </g>
            </svg>
              <p>timeline</p>
              <div className='circle'>
                <svg className='exclaimation' xmlns="http://www.w3.org/2000/svg"  version="1.1" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 100 125"><g id="a"><path fill="#ffffff" stroke="none" d=" M 53.65 73.3 Q 52.75 72.9 51.7 72.75 50.9 72.6 50 72.6 49.15 72.6 48.35 72.75 47.3 72.9 46.4 73.3 44.8 73.9 43.5 75.25 40.8 78 40.8 81.85 40.8 85.7 43.5 88.4 46.2 91.1 50 91.1 53.85 91.1 56.55 88.4 59.25 85.7 59.25 81.85 59.25 78 56.55 75.25 55.25 73.9 53.65 73.3 M 62.9 23.15 Q 64.45 10.05 50 10.15 35.6 10.2 37.25 22.95 L 43.85 68.35 56.3 68.35 62.9 23.15 Z"/></g></svg>
              </div>
            </a>
            <a className={`link ${roadmapVisible ? 'fade-in' : ''}`} href='/roadmap' aria-label='Find out more about our roadmap and future plans'>
            <svg className='icon' width="800px" height="800px" viewBox="0 0 32 32" id="icon">
              <defs>
              </defs>
              <path fill='black' d="M12,30H4a2.0023,2.0023,0,0,1-2-2V24a2.0023,2.0023,0,0,1,2-2h8a2.0023,2.0023,0,0,1,2,2v4A2.0023,2.0023,0,0,1,12,30ZM4,24v4h8V24Z"/>
              <path fill='black' d="M28,20H12a2.0023,2.0023,0,0,1-2-2V14a2.0023,2.0023,0,0,1,2-2H28a2.0023,2.0023,0,0,1,2,2v4A2.0023,2.0023,0,0,1,28,20ZM12,14v4H28V14Z"/>
              <path fill='black' d="M16,10H4A2.0023,2.0023,0,0,1,2,8V4A2.0023,2.0023,0,0,1,4,2H16a2.0023,2.0023,0,0,1,2,2V8A2.0023,2.0023,0,0,1,16,10ZM4,4V8H16V4Z"/>
            </svg>
              <p>roadmap</p>
            </a>
                <div className='socialContainerMobile'>
                  <a className={`socialLink ${archiveVisible ? 'fade-in' : ''}`} href='/' aria-label='Join our discord community and get involved!' target='_blank'>
                  <svg className='icon bigger' fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1"
                    viewBox="0 0 300 300">
                  <g id="XMLID_526_">
                    <path className="fillBlack" id="XMLID_527_" d="M14.492,208.896c0.619,0,1.143-0.509,1.232-1.226l3.365-26.671l-3.355-27.278
                      c-0.1-0.717-0.623-1.23-1.242-1.23c-0.635,0-1.176,0.524-1.26,1.23l-2.941,27.278l2.941,26.662
                      C13.316,208.377,13.857,208.896,14.492,208.896z"/>
                    <path className="fillBlack" id="XMLID_530_" d="M3.397,198.752c0.608,0,1.101-0.473,1.19-1.18l2.608-16.574l-2.608-16.884
                      c-0.09-0.685-0.582-1.18-1.19-1.18c-0.635,0-1.127,0.495-1.217,1.19L0,180.999l2.18,16.569
                      C2.27,198.269,2.762,198.752,3.397,198.752z"/>
                    <path className="fillBlack" id="XMLID_531_" d="M27.762,148.644c-0.08-0.867-0.715-1.5-1.503-1.5c-0.782,0-1.418,0.633-1.491,1.5l-2.811,32.355
                      l2.811,31.174c0.073,0.862,0.709,1.487,1.491,1.487c0.788,0,1.423-0.625,1.503-1.487l3.18-31.174L27.762,148.644z"/>
                    <path className="fillBlack" id="XMLID_532_" d="M38.152,214.916c0.922,0,1.668-0.759,1.758-1.751l3.005-32.156l-3.005-33.258
                      c-0.09-0.999-0.836-1.749-1.758-1.749c-0.935,0-1.692,0.751-1.756,1.754l-2.656,33.253l2.656,32.156
                      C36.46,214.158,37.217,214.916,38.152,214.916z"/>
                    <path className="fillBlack" id="XMLID_533_" d="M50.127,215.438c1.074,0,1.936-0.86,2.025-2.011l-0.01,0.008l2.83-32.426l-2.83-30.857
                      c-0.08-1.132-0.941-2.005-2.016-2.005c-1.09,0-1.947,0.873-2.012,2.014l-2.502,30.849l2.502,32.418
                      C48.18,214.578,49.037,215.438,50.127,215.438z"/>
                    <path className="fillBlack" id="XMLID_534_" d="M67.132,181.017l-2.655-50.172c-0.074-1.272-1.065-2.286-2.281-2.286c-1.207,0-2.195,1.013-2.269,2.286
                      l-2.35,50.172l2.35,32.418c0.074,1.278,1.063,2.278,2.269,2.278c1.217,0,2.207-1,2.281-2.278v0.009L67.132,181.017z"/>
                    <path className="fillBlack" id="XMLID_535_" d="M74.386,215.766c1.339,0,2.45-1.111,2.513-2.529v0.021l2.482-32.233l-2.482-61.656
                      c-0.063-1.418-1.174-2.529-2.513-2.529c-1.37,0-2.471,1.111-2.545,2.529l-2.185,61.656l2.195,32.222
                      C71.915,214.655,73.016,215.766,74.386,215.766z"/>
                    <path className="fillBlack" id="XMLID_536_" d="M86.645,111.435c-1.508,0-2.725,1.238-2.787,2.799l-2.033,66.801l2.033,31.884
                      c0.063,1.553,1.279,2.783,2.787,2.783c1.504,0,2.73-1.22,2.783-2.788v0.016l2.307-31.895l-2.307-66.801
                      C89.375,112.663,88.148,111.435,86.645,111.435z"/>
                    <path className="fillBlack" id="XMLID_782_" d="M99.01,215.766c1.656,0,2.975-1.336,3.037-3.056v0.019l2.133-31.693l-2.133-69.045
                      c-0.063-1.714-1.381-3.056-3.037-3.056c-1.666,0-3.005,1.342-3.031,3.056l-1.916,69.045l1.916,31.693
                      C96.005,214.43,97.344,215.766,99.01,215.766z"/>
                    <path className="fillBlack" id="XMLID_783_" d="M111.477,215.734c1.787,0,3.237-1.463,3.291-3.318v0.029l1.963-31.404l-1.963-67.289
                      c-0.054-1.854-1.504-3.311-3.291-3.311c-1.8,0-3.25,1.456-3.303,3.311l-1.725,67.289l1.736,31.389
                      C108.227,214.271,109.677,215.734,111.477,215.734z"/>
                    <path className="fillBlack" id="XMLID_784_" d="M129.359,181.041l-1.777-64.836c-0.043-2-1.609-3.571-3.551-3.571c-1.947,0-3.514,1.571-3.555,3.584
                      l-1.594,64.823l1.594,31.198c0.041,1.984,1.607,3.556,3.555,3.556c1.941,0,3.508-1.572,3.551-3.585v0.029L129.359,181.041z"/>
                    <path className="fillBlack" id="XMLID_785_" d="M136.682,215.853c2.064,0,3.773-1.717,3.805-3.828v0.017l1.613-30.984l-1.613-77.153
                      c-0.031-2.119-1.74-3.833-3.805-3.833c-2.063,0-3.767,1.722-3.809,3.844l-1.434,77.111l1.434,31.016
                      C132.915,214.136,134.619,215.853,136.682,215.853z"/>
                    <path className="fillBlack" id="XMLID_786_" d="M149.291,92.814c-2.229,0-4.037,1.849-4.074,4.103l-1.667,84.151l1.677,30.526
                      c0.027,2.225,1.836,4.068,4.064,4.068c2.195,0,4.037-1.844,4.047-4.105v0.037l1.82-30.526l-1.82-84.151
                      C153.328,94.655,151.486,92.814,149.291,92.814z"/>
                    <path className="fillBlack" id="XMLID_787_" d="M160.82,215.882c0.09,0.008,101.623,0.056,102.275,0.056c20.385,0,36.904-16.722,36.904-37.357
                      c0-20.624-16.52-37.349-36.904-37.349c-5.059,0-9.879,1.034-14.275,2.907c-2.922-33.671-30.815-60.077-64.842-60.077
                      c-8.318,0-16.429,1.662-23.593,4.469c-2.788,1.09-3.534,2.214-3.556,4.392v118.539C156.861,213.752,158.607,215.655,160.82,215.882
                      z"/>
                  </g>
                  </svg>
                  </a>
                  <a className={`socialLink ${exploreVisible ? 'fade-in' : ''}`} href='https://x.com/Selectors_' aria-label='Give us a follow on X to stay in the loop regarding the latest Selectors news!' target='_blank'>
                    <svg className='icon' width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" alt='x icon'>
                      <path className='fillBlack' d="M0.0682668 0L10.8788 14.3405L0 26H2.44837L11.9727 15.792L19.6681 26H28L16.5813 10.8528L26.7071 0H24.2588L15.4873 9.4014L8.40017 0H0.0682668ZM3.66878 1.78924H7.49647L24.3989 24.2105H20.5712L3.66878 1.78924Z"/>
                    </svg>
                  </a>
                  <a className={`socialLink ${roadmapVisible ? 'fade-in' : ''}`} href='/' aria-label='Check out our Spotify playlists!' target='_blank'>
                  <svg className='icon' width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt='spotify icon'>
                    <path className='fillBlack' fillRule="evenodd" clipRule="evenodd" d="M23.8725 13.2975C19.0375 10.4262 11.0625 10.1625 6.44625 11.5638C5.705 11.7888 4.92125 11.37 4.6975 10.6288C4.4725 9.8875 4.89 9.10375 5.6325 8.87875C10.9312 7.27 19.7387 7.58125 25.305 10.885C25.9712 11.2813 26.19 12.1413 25.795 12.8075C25.4 13.4738 24.5387 13.6937 23.8725 13.2975ZM23.715 17.5513C23.375 18.1013 22.6562 18.2737 22.1062 17.9362C18.075 15.4587 11.9288 14.74 7.15875 16.1875C6.54125 16.375 5.8875 16.0262 5.7 15.4087C5.51375 14.79 5.8625 14.1387 6.48 13.95C11.9275 12.2975 18.7 13.0975 23.33 15.9438C23.88 16.2813 24.0525 17.0025 23.715 17.5513ZM21.8788 21.635C21.61 22.0775 21.0338 22.2162 20.5938 21.9462C17.0713 19.7937 12.6375 19.3075 7.41625 20.5C6.91375 20.615 6.4125 20.3 6.2975 19.7975C6.1825 19.2938 6.49625 18.7925 7 18.6775C12.7138 17.3713 17.615 17.9338 21.5687 20.35C22.01 20.6188 22.1488 21.195 21.8788 21.635ZM15 0C6.71625 0 0 6.71625 0 15C0 23.285 6.71625 30 15 30C23.285 30 30 23.285 30 15C30 6.71625 23.285 0 15 0Z"/>
                  </svg>
                  </a>
                  <a className={`socialLink ${roadmapVisible ? 'fade-in' : ''}`} href='https://discord.gg/GYNeUYCnxX' aria-label='Check out our Discord' target='_blank'>
                  <svg width="128" height="97" viewBox="0 0 128 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M107.7 8.07C99.3467 4.24568 90.5282 1.53258 81.4699 0C80.2304 2.21588 79.1088 4.49573 78.1099 6.83C68.461 5.37602 58.6488 5.37602 48.9999 6.83C48.0005 4.49597 46.8789 2.21615 45.6399 0C36.5758 1.54552 27.7515 4.26506 19.3899 8.09C2.7899 32.65 -1.7101 56.6 0.539904 80.21C10.2613 87.3926 21.1423 92.8551 32.7099 96.36C35.3146 92.8568 37.6194 89.1403 39.5999 85.25C35.8382 83.8451 32.2076 82.1117 28.7499 80.07C29.6599 79.41 30.5499 78.73 31.4099 78.07C41.4709 82.8014 52.4519 85.2546 63.5699 85.2546C74.6879 85.2546 85.6689 82.8014 95.7299 78.07C96.5999 78.78 97.4899 79.46 98.3899 80.07C94.9256 82.115 91.2882 83.8517 87.5199 85.26C89.498 89.1485 91.8029 92.8619 94.4099 96.36C105.987 92.8691 116.877 87.4092 126.6 80.22C129.24 52.84 122.09 29.11 107.7 8.07ZM42.4499 65.69C36.1799 65.69 30.9999 60 30.9999 53C30.9999 46 35.9999 40.26 42.4299 40.26C48.8599 40.26 53.9999 46 53.8899 53C53.7799 60 48.8399 65.69 42.4499 65.69ZM84.6899 65.69C78.4099 65.69 73.2499 60 73.2499 53C73.2499 46 78.2499 40.26 84.6899 40.26C91.1299 40.26 96.2299 46 96.1199 53C96.0099 60 91.0799 65.69 84.6899 65.69Z" fill="black"/>
                  </svg>
                  </a>
                </div>
			        </div>
		        </div>
	        </nav>
        </div>
      </div>
      <div className={`header secondHeader ${shouldFadeInSecondHeader ? 'fade-in' : ''}`}>
        <a className='mintBanner' href='https://mint.cnft.tools/selectors' target='_blank'>Mint is live! Get your Selectors character here.</a>
        <div className='headerLeft'>
          <a href="/" className={`logoContainer ${logoVisible ? 'fade-in' : ''}`} aria-label="Redirect back to the root of the site" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img width="50" height="50" className={`logo ${logoHovered ? 'hovered' : ''}`} src={logoHovered ? SelectorsOrange : SelectorsWhite} alt="selectors logo"/>
          </a>
          <div className={`linkContainer ${lineVisible ? 'fade-in' : ''}`}>
          <a className={`link ${roadmapVisible ? 'fade-in' : ''}`} href='/timeline' aria-label='Find out more about our roadmap and future plans'>
            <svg className='icon' version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="99.000000pt" height="99.000000pt" viewBox="0 0 99.000000 99.000000"
            preserveAspectRatio="xMidYMid meet">
              <g className='fill' transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)"
              stroke="none">
              <path d="M79 921 l-24 -19 -3 -384 c-1 -210 0 -393 3 -406 3 -12 14 -31 25
              -42 19 -19 35 -20 418 -20 218 0 402 4 408 8 27 18 34 55 33 179 l-2 128 -8
              -129 c-5 -71 -11 -131 -14 -134 -3 -4 -97 88 -209 203 l-202 209 195 119 c108
              66 201 124 206 130 13 13 26 20 -223 -118 -116 -64 -215 -121 -217 -126 -3 -5
              87 -104 201 -221 l206 -213 -48 -3 -48 -3 -182 182 c-101 101 -185 181 -188
              178 -8 -8 -68 -300 -63 -305 2 -3 17 46 32 108 16 62 32 123 36 135 8 21 22 9
              164 -139 85 -89 154 -162 153 -162 -2 -1 -142 1 -313 4 l-309 5 -13 25 c-15
              29 -17 108 -4 143 6 16 42 44 96 77 47 29 88 50 90 48 2 -2 -7 -53 -21 -113
              -35 -160 -28 -160 16 0 20 77 34 143 30 148 -3 4 -50 -17 -104 -48 -54 -30
              -100 -55 -102 -55 -2 0 -4 19 -4 43 l0 42 103 59 c153 89 154 90 166 145 6 27
              24 108 41 180 l32 131 37 0 38 0 -28 -122 c-31 -134 -34 -168 -19 -168 11 0
              419 246 435 263 10 10 -19 -6 -265 -142 -79 -44 -145 -79 -147 -78 -1 1 12 57
              29 123 l30 121 66 7 c37 3 113 6 168 6 143 0 143 0 151 -160 3 -69 7 -127 9
              -129 7 -7 4 246 -2 264 -17 42 -44 45 -447 45 -364 0 -386 -1 -408 -19z m310
              -28 c0 -10 -17 -88 -38 -173 l-37 -155 -107 -63 c-60 -35 -110 -62 -112 -61
              -1 2 -5 101 -6 220 -3 172 -1 219 10 232 11 14 35 17 152 17 126 0 139 -2 138
              -17z"/>
              <path d="M221 104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
              <path d="M331 104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
              </g>
            </svg>
              <p>timeline</p>
              <div className='circle'>
                <svg className='exclaimation' xmlns="http://www.w3.org/2000/svg"  version="1.1" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 100 125"><g id="a"><path fill="#ffffff" stroke="none" d=" M 53.65 73.3 Q 52.75 72.9 51.7 72.75 50.9 72.6 50 72.6 49.15 72.6 48.35 72.75 47.3 72.9 46.4 73.3 44.8 73.9 43.5 75.25 40.8 78 40.8 81.85 40.8 85.7 43.5 88.4 46.2 91.1 50 91.1 53.85 91.1 56.55 88.4 59.25 85.7 59.25 81.85 59.25 78 56.55 75.25 55.25 73.9 53.65 73.3 M 62.9 23.15 Q 64.45 10.05 50 10.15 35.6 10.2 37.25 22.95 L 43.85 68.35 56.3 68.35 62.9 23.15 Z"/></g></svg>
              </div>
            </a>
            <a className={`link ${roadmapVisible ? 'fade-in' : ''}`} href='/roadmap' aria-label='Find out more about our roadmap and future plans'>
            <svg className='icon' width="800px" height="800px" viewBox="0 0 32 32" id="icon">
              <defs>
              </defs>
              <path fill='black' d="M12,30H4a2.0023,2.0023,0,0,1-2-2V24a2.0023,2.0023,0,0,1,2-2h8a2.0023,2.0023,0,0,1,2,2v4A2.0023,2.0023,0,0,1,12,30ZM4,24v4h8V24Z"/>
              <path fill='black' d="M28,20H12a2.0023,2.0023,0,0,1-2-2V14a2.0023,2.0023,0,0,1,2-2H28a2.0023,2.0023,0,0,1,2,2v4A2.0023,2.0023,0,0,1,28,20ZM12,14v4H28V14Z"/>
              <path fill='black' d="M16,10H4A2.0023,2.0023,0,0,1,2,8V4A2.0023,2.0023,0,0,1,4,2H16a2.0023,2.0023,0,0,1,2,2V8A2.0023,2.0023,0,0,1,16,10ZM4,4V8H16V4Z"/>
            </svg>
              <p>roadmap</p>
            </a>
          </div>
        </div>
        <div className='socialContainer'>
          <a className={`socialLink ${archiveVisible ? 'fade-in' : ''}`} href='/' aria-label='Join our discord community and get involved!' target='_blank'>
          <svg className='icon bigger' fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1"
            viewBox="0 0 300 300">
          <g id="XMLID_526_">
            <path className="fill" id="XMLID_527_" d="M14.492,208.896c0.619,0,1.143-0.509,1.232-1.226l3.365-26.671l-3.355-27.278
              c-0.1-0.717-0.623-1.23-1.242-1.23c-0.635,0-1.176,0.524-1.26,1.23l-2.941,27.278l2.941,26.662
              C13.316,208.377,13.857,208.896,14.492,208.896z"/>
            <path className="fill" id="XMLID_530_" d="M3.397,198.752c0.608,0,1.101-0.473,1.19-1.18l2.608-16.574l-2.608-16.884
              c-0.09-0.685-0.582-1.18-1.19-1.18c-0.635,0-1.127,0.495-1.217,1.19L0,180.999l2.18,16.569
              C2.27,198.269,2.762,198.752,3.397,198.752z"/>
            <path className="fill" id="XMLID_531_" d="M27.762,148.644c-0.08-0.867-0.715-1.5-1.503-1.5c-0.782,0-1.418,0.633-1.491,1.5l-2.811,32.355
              l2.811,31.174c0.073,0.862,0.709,1.487,1.491,1.487c0.788,0,1.423-0.625,1.503-1.487l3.18-31.174L27.762,148.644z"/>
            <path className="fill" id="XMLID_532_" d="M38.152,214.916c0.922,0,1.668-0.759,1.758-1.751l3.005-32.156l-3.005-33.258
              c-0.09-0.999-0.836-1.749-1.758-1.749c-0.935,0-1.692,0.751-1.756,1.754l-2.656,33.253l2.656,32.156
              C36.46,214.158,37.217,214.916,38.152,214.916z"/>
            <path className="fill" id="XMLID_533_" d="M50.127,215.438c1.074,0,1.936-0.86,2.025-2.011l-0.01,0.008l2.83-32.426l-2.83-30.857
              c-0.08-1.132-0.941-2.005-2.016-2.005c-1.09,0-1.947,0.873-2.012,2.014l-2.502,30.849l2.502,32.418
              C48.18,214.578,49.037,215.438,50.127,215.438z"/>
            <path className="fill" id="XMLID_534_" d="M67.132,181.017l-2.655-50.172c-0.074-1.272-1.065-2.286-2.281-2.286c-1.207,0-2.195,1.013-2.269,2.286
              l-2.35,50.172l2.35,32.418c0.074,1.278,1.063,2.278,2.269,2.278c1.217,0,2.207-1,2.281-2.278v0.009L67.132,181.017z"/>
            <path className="fill" id="XMLID_535_" d="M74.386,215.766c1.339,0,2.45-1.111,2.513-2.529v0.021l2.482-32.233l-2.482-61.656
              c-0.063-1.418-1.174-2.529-2.513-2.529c-1.37,0-2.471,1.111-2.545,2.529l-2.185,61.656l2.195,32.222
              C71.915,214.655,73.016,215.766,74.386,215.766z"/>
            <path className="fill" id="XMLID_536_" d="M86.645,111.435c-1.508,0-2.725,1.238-2.787,2.799l-2.033,66.801l2.033,31.884
              c0.063,1.553,1.279,2.783,2.787,2.783c1.504,0,2.73-1.22,2.783-2.788v0.016l2.307-31.895l-2.307-66.801
              C89.375,112.663,88.148,111.435,86.645,111.435z"/>
            <path className="fill" id="XMLID_782_" d="M99.01,215.766c1.656,0,2.975-1.336,3.037-3.056v0.019l2.133-31.693l-2.133-69.045
              c-0.063-1.714-1.381-3.056-3.037-3.056c-1.666,0-3.005,1.342-3.031,3.056l-1.916,69.045l1.916,31.693
              C96.005,214.43,97.344,215.766,99.01,215.766z"/>
            <path className="fill" id="XMLID_783_" d="M111.477,215.734c1.787,0,3.237-1.463,3.291-3.318v0.029l1.963-31.404l-1.963-67.289
              c-0.054-1.854-1.504-3.311-3.291-3.311c-1.8,0-3.25,1.456-3.303,3.311l-1.725,67.289l1.736,31.389
              C108.227,214.271,109.677,215.734,111.477,215.734z"/>
            <path className="fill" id="XMLID_784_" d="M129.359,181.041l-1.777-64.836c-0.043-2-1.609-3.571-3.551-3.571c-1.947,0-3.514,1.571-3.555,3.584
              l-1.594,64.823l1.594,31.198c0.041,1.984,1.607,3.556,3.555,3.556c1.941,0,3.508-1.572,3.551-3.585v0.029L129.359,181.041z"/>
            <path className="fill" id="XMLID_785_" d="M136.682,215.853c2.064,0,3.773-1.717,3.805-3.828v0.017l1.613-30.984l-1.613-77.153
              c-0.031-2.119-1.74-3.833-3.805-3.833c-2.063,0-3.767,1.722-3.809,3.844l-1.434,77.111l1.434,31.016
              C132.915,214.136,134.619,215.853,136.682,215.853z"/>
            <path className="fill" id="XMLID_786_" d="M149.291,92.814c-2.229,0-4.037,1.849-4.074,4.103l-1.667,84.151l1.677,30.526
              c0.027,2.225,1.836,4.068,4.064,4.068c2.195,0,4.037-1.844,4.047-4.105v0.037l1.82-30.526l-1.82-84.151
              C153.328,94.655,151.486,92.814,149.291,92.814z"/>
            <path className="fill" id="XMLID_787_" d="M160.82,215.882c0.09,0.008,101.623,0.056,102.275,0.056c20.385,0,36.904-16.722,36.904-37.357
              c0-20.624-16.52-37.349-36.904-37.349c-5.059,0-9.879,1.034-14.275,2.907c-2.922-33.671-30.815-60.077-64.842-60.077
              c-8.318,0-16.429,1.662-23.593,4.469c-2.788,1.09-3.534,2.214-3.556,4.392v118.539C156.861,213.752,158.607,215.655,160.82,215.882
              z"/>
          </g>
          </svg>
          </a>
          <a className={`socialLink ${exploreVisible ? 'fade-in' : ''}`} href='https://x.com/Selectors_'>
            <svg className='icon' width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" alt='x icon' aria-label='Give us a follow on X to stay in the loop regarding the latest Selectors news!' target='_blank'>
              <path className='fill' d="M0.0682668 0L10.8788 14.3405L0 26H2.44837L11.9727 15.792L19.6681 26H28L16.5813 10.8528L26.7071 0H24.2588L15.4873 9.4014L8.40017 0H0.0682668ZM3.66878 1.78924H7.49647L24.3989 24.2105H20.5712L3.66878 1.78924Z"/>
            </svg>
          </a>
          <a className={`socialLink ${roadmapVisible ? 'fade-in' : ''}`} href='/'>
          <svg className='icon' width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt='spotify icon' aria-label='Check out our Spotify playlists!' target='_blank'>
            <path className='fill' fillRule="evenodd" clipRule="evenodd" d="M23.8725 13.2975C19.0375 10.4262 11.0625 10.1625 6.44625 11.5638C5.705 11.7888 4.92125 11.37 4.6975 10.6288C4.4725 9.8875 4.89 9.10375 5.6325 8.87875C10.9312 7.27 19.7387 7.58125 25.305 10.885C25.9712 11.2813 26.19 12.1413 25.795 12.8075C25.4 13.4738 24.5387 13.6937 23.8725 13.2975ZM23.715 17.5513C23.375 18.1013 22.6562 18.2737 22.1062 17.9362C18.075 15.4587 11.9288 14.74 7.15875 16.1875C6.54125 16.375 5.8875 16.0262 5.7 15.4087C5.51375 14.79 5.8625 14.1387 6.48 13.95C11.9275 12.2975 18.7 13.0975 23.33 15.9438C23.88 16.2813 24.0525 17.0025 23.715 17.5513ZM21.8788 21.635C21.61 22.0775 21.0338 22.2162 20.5938 21.9462C17.0713 19.7937 12.6375 19.3075 7.41625 20.5C6.91375 20.615 6.4125 20.3 6.2975 19.7975C6.1825 19.2938 6.49625 18.7925 7 18.6775C12.7138 17.3713 17.615 17.9338 21.5687 20.35C22.01 20.6188 22.1488 21.195 21.8788 21.635ZM15 0C6.71625 0 0 6.71625 0 15C0 23.285 6.71625 30 15 30C23.285 30 30 23.285 30 15C30 6.71625 23.285 0 15 0Z"/>
          </svg>
          </a>
          <a className={`socialLink ${roadmapVisible ? 'fade-in' : ''}`} href='https://discord.gg/GYNeUYCnxX' aria-label='Check out our Discord' target='_blank'>
          <svg width="128" height="97" viewBox="0 0 128 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M107.7 8.07C99.3467 4.24568 90.5282 1.53258 81.4699 0C80.2304 2.21588 79.1088 4.49573 78.1099 6.83C68.461 5.37602 58.6488 5.37602 48.9999 6.83C48.0005 4.49597 46.8789 2.21615 45.6399 0C36.5758 1.54552 27.7515 4.26506 19.3899 8.09C2.7899 32.65 -1.7101 56.6 0.539904 80.21C10.2613 87.3926 21.1423 92.8551 32.7099 96.36C35.3146 92.8568 37.6194 89.1403 39.5999 85.25C35.8382 83.8451 32.2076 82.1117 28.7499 80.07C29.6599 79.41 30.5499 78.73 31.4099 78.07C41.4709 82.8014 52.4519 85.2546 63.5699 85.2546C74.6879 85.2546 85.6689 82.8014 95.7299 78.07C96.5999 78.78 97.4899 79.46 98.3899 80.07C94.9256 82.115 91.2882 83.8517 87.5199 85.26C89.498 89.1485 91.8029 92.8619 94.4099 96.36C105.987 92.8691 116.877 87.4092 126.6 80.22C129.24 52.84 122.09 29.11 107.7 8.07ZM42.4499 65.69C36.1799 65.69 30.9999 60 30.9999 53C30.9999 46 35.9999 40.26 42.4299 40.26C48.8599 40.26 53.9999 46 53.8899 53C53.7799 60 48.8399 65.69 42.4499 65.69ZM84.6899 65.69C78.4099 65.69 73.2499 60 73.2499 53C73.2499 46 78.2499 40.26 84.6899 40.26C91.1299 40.26 96.2299 46 96.1199 53C96.0099 60 91.0799 65.69 84.6899 65.69Z" fill="black"/>
          </svg>
          </a>
        </div>
        <div className='mobile mobileSecondHeader'>
          <nav role='navigation'>
		        <div id="menuToggle">
			        <input type="checkbox" />
			        <span></span>
			        <span></span>
			        <span className='last'></span>
			        <div id="menu">
              <a className={`link ${roadmapVisible ? 'fade-in' : ''}`} href='/timeline' aria-label='Find out more about our roadmap and future plans'>
            <svg className='icon' version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="99.000000pt" height="99.000000pt" viewBox="0 0 99.000000 99.000000"
            preserveAspectRatio="xMidYMid meet">
              <g className='fill' transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)"
              stroke="none">
              <path d="M79 921 l-24 -19 -3 -384 c-1 -210 0 -393 3 -406 3 -12 14 -31 25
              -42 19 -19 35 -20 418 -20 218 0 402 4 408 8 27 18 34 55 33 179 l-2 128 -8
              -129 c-5 -71 -11 -131 -14 -134 -3 -4 -97 88 -209 203 l-202 209 195 119 c108
              66 201 124 206 130 13 13 26 20 -223 -118 -116 -64 -215 -121 -217 -126 -3 -5
              87 -104 201 -221 l206 -213 -48 -3 -48 -3 -182 182 c-101 101 -185 181 -188
              178 -8 -8 -68 -300 -63 -305 2 -3 17 46 32 108 16 62 32 123 36 135 8 21 22 9
              164 -139 85 -89 154 -162 153 -162 -2 -1 -142 1 -313 4 l-309 5 -13 25 c-15
              29 -17 108 -4 143 6 16 42 44 96 77 47 29 88 50 90 48 2 -2 -7 -53 -21 -113
              -35 -160 -28 -160 16 0 20 77 34 143 30 148 -3 4 -50 -17 -104 -48 -54 -30
              -100 -55 -102 -55 -2 0 -4 19 -4 43 l0 42 103 59 c153 89 154 90 166 145 6 27
              24 108 41 180 l32 131 37 0 38 0 -28 -122 c-31 -134 -34 -168 -19 -168 11 0
              419 246 435 263 10 10 -19 -6 -265 -142 -79 -44 -145 -79 -147 -78 -1 1 12 57
              29 123 l30 121 66 7 c37 3 113 6 168 6 143 0 143 0 151 -160 3 -69 7 -127 9
              -129 7 -7 4 246 -2 264 -17 42 -44 45 -447 45 -364 0 -386 -1 -408 -19z m310
              -28 c0 -10 -17 -88 -38 -173 l-37 -155 -107 -63 c-60 -35 -110 -62 -112 -61
              -1 2 -5 101 -6 220 -3 172 -1 219 10 232 11 14 35 17 152 17 126 0 139 -2 138
              -17z"/>
              <path d="M221 104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
              <path d="M331 104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
              </g>
            </svg>
              <p>timeline</p>
              <div className='circle'>
                <svg className='exclaimation' xmlns="http://www.w3.org/2000/svg"  version="1.1" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 100 125"><g id="a"><path fill="#ffffff" stroke="none" d=" M 53.65 73.3 Q 52.75 72.9 51.7 72.75 50.9 72.6 50 72.6 49.15 72.6 48.35 72.75 47.3 72.9 46.4 73.3 44.8 73.9 43.5 75.25 40.8 78 40.8 81.85 40.8 85.7 43.5 88.4 46.2 91.1 50 91.1 53.85 91.1 56.55 88.4 59.25 85.7 59.25 81.85 59.25 78 56.55 75.25 55.25 73.9 53.65 73.3 M 62.9 23.15 Q 64.45 10.05 50 10.15 35.6 10.2 37.25 22.95 L 43.85 68.35 56.3 68.35 62.9 23.15 Z"/></g></svg>
              </div>
            </a>
            <a className={`link ${roadmapVisible ? 'fade-in' : ''}`} href='/roadmap' aria-label='Find out more about our roadmap and future plans'>
            <svg className='icon' width="800px" height="800px" viewBox="0 0 32 32" id="icon">
              <defs>
              </defs>
              <path fill='white' d="M12,30H4a2.0023,2.0023,0,0,1-2-2V24a2.0023,2.0023,0,0,1,2-2h8a2.0023,2.0023,0,0,1,2,2v4A2.0023,2.0023,0,0,1,12,30ZM4,24v4h8V24Z"/>
              <path fill='white' d="M28,20H12a2.0023,2.0023,0,0,1-2-2V14a2.0023,2.0023,0,0,1,2-2H28a2.0023,2.0023,0,0,1,2,2v4A2.0023,2.0023,0,0,1,28,20ZM12,14v4H28V14Z"/>
              <path fill='white' d="M16,10H4A2.0023,2.0023,0,0,1,2,8V4A2.0023,2.0023,0,0,1,4,2H16a2.0023,2.0023,0,0,1,2,2V8A2.0023,2.0023,0,0,1,16,10ZM4,4V8H16V4Z"/>
            </svg>
              <p>roadmap</p>
            </a>
                <div className='socialContainerMobile'>
                  <a className={`socialLink ${archiveVisible ? 'fade-in' : ''}`} href='/' aria-label='Join our discord community and get involved!' target='_blank'>
                  <svg className='icon bigger' fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1"
                    viewBox="0 0 300 300">
                  <g id="XMLID_526_">
                    <path className="fillBlack" id="XMLID_527_" d="M14.492,208.896c0.619,0,1.143-0.509,1.232-1.226l3.365-26.671l-3.355-27.278
                      c-0.1-0.717-0.623-1.23-1.242-1.23c-0.635,0-1.176,0.524-1.26,1.23l-2.941,27.278l2.941,26.662
                      C13.316,208.377,13.857,208.896,14.492,208.896z"/>
                    <path className="fillBlack" id="XMLID_530_" d="M3.397,198.752c0.608,0,1.101-0.473,1.19-1.18l2.608-16.574l-2.608-16.884
                      c-0.09-0.685-0.582-1.18-1.19-1.18c-0.635,0-1.127,0.495-1.217,1.19L0,180.999l2.18,16.569
                      C2.27,198.269,2.762,198.752,3.397,198.752z"/>
                    <path className="fillBlack" id="XMLID_531_" d="M27.762,148.644c-0.08-0.867-0.715-1.5-1.503-1.5c-0.782,0-1.418,0.633-1.491,1.5l-2.811,32.355
                      l2.811,31.174c0.073,0.862,0.709,1.487,1.491,1.487c0.788,0,1.423-0.625,1.503-1.487l3.18-31.174L27.762,148.644z"/>
                    <path className="fillBlack" id="XMLID_532_" d="M38.152,214.916c0.922,0,1.668-0.759,1.758-1.751l3.005-32.156l-3.005-33.258
                      c-0.09-0.999-0.836-1.749-1.758-1.749c-0.935,0-1.692,0.751-1.756,1.754l-2.656,33.253l2.656,32.156
                      C36.46,214.158,37.217,214.916,38.152,214.916z"/>
                    <path className="fillBlack" id="XMLID_533_" d="M50.127,215.438c1.074,0,1.936-0.86,2.025-2.011l-0.01,0.008l2.83-32.426l-2.83-30.857
                      c-0.08-1.132-0.941-2.005-2.016-2.005c-1.09,0-1.947,0.873-2.012,2.014l-2.502,30.849l2.502,32.418
                      C48.18,214.578,49.037,215.438,50.127,215.438z"/>
                    <path className="fillBlack" id="XMLID_534_" d="M67.132,181.017l-2.655-50.172c-0.074-1.272-1.065-2.286-2.281-2.286c-1.207,0-2.195,1.013-2.269,2.286
                      l-2.35,50.172l2.35,32.418c0.074,1.278,1.063,2.278,2.269,2.278c1.217,0,2.207-1,2.281-2.278v0.009L67.132,181.017z"/>
                    <path className="fillBlack" id="XMLID_535_" d="M74.386,215.766c1.339,0,2.45-1.111,2.513-2.529v0.021l2.482-32.233l-2.482-61.656
                      c-0.063-1.418-1.174-2.529-2.513-2.529c-1.37,0-2.471,1.111-2.545,2.529l-2.185,61.656l2.195,32.222
                      C71.915,214.655,73.016,215.766,74.386,215.766z"/>
                    <path className="fillBlack" id="XMLID_536_" d="M86.645,111.435c-1.508,0-2.725,1.238-2.787,2.799l-2.033,66.801l2.033,31.884
                      c0.063,1.553,1.279,2.783,2.787,2.783c1.504,0,2.73-1.22,2.783-2.788v0.016l2.307-31.895l-2.307-66.801
                      C89.375,112.663,88.148,111.435,86.645,111.435z"/>
                    <path className="fillBlack" id="XMLID_782_" d="M99.01,215.766c1.656,0,2.975-1.336,3.037-3.056v0.019l2.133-31.693l-2.133-69.045
                      c-0.063-1.714-1.381-3.056-3.037-3.056c-1.666,0-3.005,1.342-3.031,3.056l-1.916,69.045l1.916,31.693
                      C96.005,214.43,97.344,215.766,99.01,215.766z"/>
                    <path className="fillBlack" id="XMLID_783_" d="M111.477,215.734c1.787,0,3.237-1.463,3.291-3.318v0.029l1.963-31.404l-1.963-67.289
                      c-0.054-1.854-1.504-3.311-3.291-3.311c-1.8,0-3.25,1.456-3.303,3.311l-1.725,67.289l1.736,31.389
                      C108.227,214.271,109.677,215.734,111.477,215.734z"/>
                    <path className="fillBlack" id="XMLID_784_" d="M129.359,181.041l-1.777-64.836c-0.043-2-1.609-3.571-3.551-3.571c-1.947,0-3.514,1.571-3.555,3.584
                      l-1.594,64.823l1.594,31.198c0.041,1.984,1.607,3.556,3.555,3.556c1.941,0,3.508-1.572,3.551-3.585v0.029L129.359,181.041z"/>
                    <path className="fillBlack" id="XMLID_785_" d="M136.682,215.853c2.064,0,3.773-1.717,3.805-3.828v0.017l1.613-30.984l-1.613-77.153
                      c-0.031-2.119-1.74-3.833-3.805-3.833c-2.063,0-3.767,1.722-3.809,3.844l-1.434,77.111l1.434,31.016
                      C132.915,214.136,134.619,215.853,136.682,215.853z"/>
                    <path className="fillBlack" id="XMLID_786_" d="M149.291,92.814c-2.229,0-4.037,1.849-4.074,4.103l-1.667,84.151l1.677,30.526
                      c0.027,2.225,1.836,4.068,4.064,4.068c2.195,0,4.037-1.844,4.047-4.105v0.037l1.82-30.526l-1.82-84.151
                      C153.328,94.655,151.486,92.814,149.291,92.814z"/>
                    <path className="fillBlack" id="XMLID_787_" d="M160.82,215.882c0.09,0.008,101.623,0.056,102.275,0.056c20.385,0,36.904-16.722,36.904-37.357
                      c0-20.624-16.52-37.349-36.904-37.349c-5.059,0-9.879,1.034-14.275,2.907c-2.922-33.671-30.815-60.077-64.842-60.077
                      c-8.318,0-16.429,1.662-23.593,4.469c-2.788,1.09-3.534,2.214-3.556,4.392v118.539C156.861,213.752,158.607,215.655,160.82,215.882
                      z"/>
                  </g>
                  </svg>
                  </a>
                  <a className={`socialLink ${exploreVisible ? 'fade-in' : ''}`} href='https://x.com/Selectors_' aria-label='Give us a follow on X to stay in the loop regarding the latest Selectors news!' target='_blank'>
                    <svg className='icon' width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" alt='x icon'>
                      <path className='fillBlack' d="M0.0682668 0L10.8788 14.3405L0 26H2.44837L11.9727 15.792L19.6681 26H28L16.5813 10.8528L26.7071 0H24.2588L15.4873 9.4014L8.40017 0H0.0682668ZM3.66878 1.78924H7.49647L24.3989 24.2105H20.5712L3.66878 1.78924Z"/>
                    </svg>
                  </a>
                  <a className={`socialLink ${roadmapVisible ? 'fade-in' : ''}`} href='/' aria-label='Check out our Spotify playlists!'>
                  <svg className='icon' width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt='spotify icon' target='_blank'>
                    <path className='fillBlack' fillRule="evenodd" clipRule="evenodd" d="M23.8725 13.2975C19.0375 10.4262 11.0625 10.1625 6.44625 11.5638C5.705 11.7888 4.92125 11.37 4.6975 10.6288C4.4725 9.8875 4.89 9.10375 5.6325 8.87875C10.9312 7.27 19.7387 7.58125 25.305 10.885C25.9712 11.2813 26.19 12.1413 25.795 12.8075C25.4 13.4738 24.5387 13.6937 23.8725 13.2975ZM23.715 17.5513C23.375 18.1013 22.6562 18.2737 22.1062 17.9362C18.075 15.4587 11.9288 14.74 7.15875 16.1875C6.54125 16.375 5.8875 16.0262 5.7 15.4087C5.51375 14.79 5.8625 14.1387 6.48 13.95C11.9275 12.2975 18.7 13.0975 23.33 15.9438C23.88 16.2813 24.0525 17.0025 23.715 17.5513ZM21.8788 21.635C21.61 22.0775 21.0338 22.2162 20.5938 21.9462C17.0713 19.7937 12.6375 19.3075 7.41625 20.5C6.91375 20.615 6.4125 20.3 6.2975 19.7975C6.1825 19.2938 6.49625 18.7925 7 18.6775C12.7138 17.3713 17.615 17.9338 21.5687 20.35C22.01 20.6188 22.1488 21.195 21.8788 21.635ZM15 0C6.71625 0 0 6.71625 0 15C0 23.285 6.71625 30 15 30C23.285 30 30 23.285 30 15C30 6.71625 23.285 0 15 0Z"/>
                  </svg>
                  </a>
                  <a className={`socialLink ${roadmapVisible ? 'fade-in' : ''}`} href='https://discord.gg/GYNeUYCnxX' aria-label='Check out our Discord' target='_blank'>
                  <svg width="128" height="97" viewBox="0 0 128 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M107.7 8.07C99.3467 4.24568 90.5282 1.53258 81.4699 0C80.2304 2.21588 79.1088 4.49573 78.1099 6.83C68.461 5.37602 58.6488 5.37602 48.9999 6.83C48.0005 4.49597 46.8789 2.21615 45.6399 0C36.5758 1.54552 27.7515 4.26506 19.3899 8.09C2.7899 32.65 -1.7101 56.6 0.539904 80.21C10.2613 87.3926 21.1423 92.8551 32.7099 96.36C35.3146 92.8568 37.6194 89.1403 39.5999 85.25C35.8382 83.8451 32.2076 82.1117 28.7499 80.07C29.6599 79.41 30.5499 78.73 31.4099 78.07C41.4709 82.8014 52.4519 85.2546 63.5699 85.2546C74.6879 85.2546 85.6689 82.8014 95.7299 78.07C96.5999 78.78 97.4899 79.46 98.3899 80.07C94.9256 82.115 91.2882 83.8517 87.5199 85.26C89.498 89.1485 91.8029 92.8619 94.4099 96.36C105.987 92.8691 116.877 87.4092 126.6 80.22C129.24 52.84 122.09 29.11 107.7 8.07ZM42.4499 65.69C36.1799 65.69 30.9999 60 30.9999 53C30.9999 46 35.9999 40.26 42.4299 40.26C48.8599 40.26 53.9999 46 53.8899 53C53.7799 60 48.8399 65.69 42.4499 65.69ZM84.6899 65.69C78.4099 65.69 73.2499 60 73.2499 53C73.2499 46 78.2499 40.26 84.6899 40.26C91.1299 40.26 96.2299 46 96.1199 53C96.0099 60 91.0799 65.69 84.6899 65.69Z" fill="black"/>
                  </svg>
                  </a>
                </div>
			        </div>
		        </div>
	        </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
