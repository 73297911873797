import React, { useState, useEffect } from 'react';
import charles from '../assets/team/charles.webp';
import sunn from '../assets/team/sunn.webp';
import kate from '../assets/team/kate.webp';
import hinshey from '../assets/team/hinshey.webp';
import aDoggy from '../assets/team/aDoggy.webp';
import placeholder1 from '../assets/team/placeholder1.webp';
import placeholder2 from '../assets/team/placeholder2.webp';
import placeholder3 from '../assets/team/placeholder3.webp';
function Team() {
  return (
    <div className='team' id='teamComponent'>
      <p className='title'>Want To Join The Team?</p>
      <div className='teamContainer'>
        <div className='teamMember'>
          <a className='imgContainer' href='' target="_blank">
            <img src={placeholder2} alt='team placeholder image' width="208" height="208"/>
          </a>
          <p className='info'>Discord Moderator</p>
        </div>
        <div className='teamMember'>
          <a className='imgContainer' href='' target="_blank">
            <img src={placeholder2} alt='team placeholder image' width="208" height="208"/>
          </a>
          <p className='info'>Discord Moderator</p>
        </div>
        <div className='teamMember'>
          <a className='imgContainer' href='' target="_blank">
            <img src={placeholder1} alt='team placeholder image' width="208" height="208"/>
          </a>
          <p className='info'>Community Manager</p>
        </div>
        <div className='teamMember'>
          <a className='imgContainer' href='' target="_blank">
            <img src={placeholder3} alt='team placeholder image' width="208" height="208"/>
          </a>
          <p className='info'>Graphics Designer</p>
        </div>
      </div>
      <div className='contactContainer'>
        <a className='twitterLink' href='https://x.com/Selectors_' aria-label='Give us a follow on X to stay in the loop regarding the latest Selectors news!' target='_blank'>
          <svg className='icon' width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" alt='x icon'>
            <path className='fillWhite' d="M0.0682668 0L10.8788 14.3405L0 26H2.44837L11.9727 15.792L19.6681 26H28L16.5813 10.8528L26.7071 0H24.2588L15.4873 9.4014L8.40017 0H0.0682668ZM3.66878 1.78924H7.49647L24.3989 24.2105H20.5712L3.66878 1.78924Z"/>
          </svg>
        </a>
        <p className='text'>Contact us on X if you're interested</p>
      </div>
    </div>
  );
}

export default Team;
