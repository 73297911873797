import React from 'react';
import SelectorsWhite from '../assets/logos/selectorsWhite.webp';

function Roadmap() {
  return (
    <div className="roadmapPage">
      <div className="header">
        <div className="headerLeft">
          <a href="/" className="logoContainer" aria-label="Redirect back to the root of the site">
            <img width='50' height='50' className="logo" src={SelectorsWhite} alt="selectors logo" />
          </a>
        </div>
      </div>
      <div className='container'>
        <p className='text'>Coming soon...</p>
      </div>
    </div>
  );
}

export default Roadmap;
