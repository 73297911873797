import React, { useState, useEffect } from 'react';
import SelectorsWhite from '../assets/logos/selectorsWhite.webp';
import Oct6_2023 from '../assets/timeline/oct6-2023.png';
import Oct9_2023 from '../assets/timeline/oct9-2023.png';
import Oct19_2023 from '../assets/timeline/oct19-2023.png';
import Oct20_2023 from '../assets/timeline/oct20-2023.png';
import Oct27_2023 from '../assets/timeline/oct27-2023.png';
import Oct31_2023 from '../assets/timeline/oct31-2023.png';
import Nov4_2023 from '../assets/timeline/nov4-2023.png';
import Dec7_2023 from '../assets/timeline/dec7-2023.png';
import Feb15_2024 from '../assets/timeline/feb15-2024.png';
import Feb17_2024 from '../assets/timeline/feb17-2024.png';
import Mar4_2024 from '../assets/timeline/mar4-2024.png';
import May2_2024 from '../assets/timeline/may2-2024.png';
import May9_2024 from '../assets/timeline/may9-2024.png';

function Timeline() {
  return (
      <div className='timelinePage'>
        <div className='header'>
            <div className='headerLeft'>
                <a href="/" className='logoContainer' aria-label="Redirect back to the root of the site">
                <img className='logo' src={SelectorsWhite} alt="selectors logo"/>
                </a>
            </div>
        </div>
        <div className='timelineContainer'>
            <div className='line'></div>
            <div className='updates'>
                <div className='updateContainer left'>
                    <p className='date'>6th Oct 2023</p>
                    <p className='update text'>Selectors Goes Live: Intro post</p>
                    <a href='https://x.com/Selectors_/status/1710324032313368761' target='_blank'>
                        <img width='126' height='174' src={Oct6_2023} alt='oct 6 2023'/>
                    </a>
                </div>
                <div className='updateContainer right'>
                    <p className='date'>9th Oct 2023</p>
                    <p className='update text'>Selectors Archive: The first Selector artist curated playlist (S1/01)</p>
                    <a href='https://x.com/Selectors_/status/1711444172975010002' target='_blank'>
                        <img width='126' height='168' src={Oct9_2023} alt='oct 9 2023'/>
                    </a>
                </div>
                <div className='updateContainer left'>
                    <p className='date'>19th Oct 2023</p>
                    <p className='update text'>Selectors Archive: Selector artist curated playlist (S1/02)</p>
                    <a href='https://x.com/Selectors_/status/1715061054705631638' target='_blank'>
                        <img width='126' height='169' src={Oct19_2023} alt='oct 19 2023'/>
                    </a>
                </div>
                <div className='updateContainer right'>
                    <p className='date'>20th Oct 2023</p>
                    <p className='update text'>Selectors Archive: Selector artist mix (S1/01)</p>
                    <a href='https://x.com/Selectors_/status/1715413447653933461' target='_blank'>
                        <img width='126' height='188' src={Oct20_2023} alt='oct 20 2023'/>
                    </a>
                </div>
                <div className='updateContainer left'>
                    <p className='date'>27th Oct 2023</p>
                    <p className='update text'>Selectors Archive: Selector artist mix (S1/01)</p>
                    <a href='https://x.com/Selectors_/status/1717956982173286584' target='_blank'>
                        <img width='126' height='203' src={Oct27_2023} alt='oct 27 2023'/>
                    </a>
                </div>
                <div className='updateContainer right'>
                    <p className='date'>31st Oct 2023</p>
                    <p className='update text'>Selectors Archive: First episode of the Select Fables Series (S1/01)</p>
                    <a href='https://x.com/Selectors_/status/1719457408185971172' target='_blank'>
                        <img width='126' height='272' src={Oct31_2023} alt='oct 31 2023'/>
                    </a>
                </div>
                <div className='updateContainer left'>
                    <p className='date'>4th Nov 2023</p>
                    <p className='update text'>Introducing The Selectors Members Card</p>
                    <a href='https://x.com/Selectors_/status/1720780433804214436' target='_blank'>
                        <img width='126' height='149' src={Nov4_2023} alt='nov 4 2023'/>
                    </a>
                </div>
                <div className='updateContainer right'>
                    <p className='date'>7th Dec 2023</p>
                    <p className='update text'>Selectors Archive: Select Fables Series (S1/02)</p>
                    <a href='https://x.com/Selectors_/status/1732729518308106458' target='_blank'>
                        <img width='126' height='319' src={Dec7_2023} alt='dec 7 2023'/>
                    </a>
                </div>
                <div className='updateContainer left'>
                    <p className='date'>15th Feb 2024</p>
                    <p className='update text'>Selectors Archive: Select Fables Series (S1/03)</p>
                    <a href='https://twitter.com/Selectors_/status/1758804143538352441?s=20' target='_blank'>
                        <img width='126' height='319' src={Feb15_2024} alt='feb 15 2024'/>
                    </a>
                </div>
                <div className='updateContainer right'>
                    <p className='date'>17th Feb 2024</p>
                    <p className='update text'>GM RADIO RELEASE</p>
                    <a href='https://twitter.com/Selectors_/status/1758804143538352441?s=20' target='_blank'>
                        <img width='126' height='319' src={Feb17_2024} alt='feb 17 2024'/>
                    </a>
                </div>
                <div className='updateContainer left'>
                    <p className='date'>4th Mar 2024</p>
                    <p className='update text'>Selectors Members Card Release (Whitelist)</p>
                    <a href='https://x.com/Selectors_/status/1720780433804214436' target='_blank'>
                        <img width='126' height='149' src={Mar4_2024} alt='mar 4 2024'/>
                    </a>
                </div>
                <div className='updateContainer right'>
                    <p className='date'>2nd May 2024</p>
                    <p className='update text'>Selectors Archive: Select Fables Series (S1/04)</p>
                    <a href='https://x.com/Selectors_/status/1732729518308106458' target='_blank'>
                        <img width='126' height='319' src={May2_2024} alt='may 2 2024'/>
                    </a>
                </div>
                <div className='updateContainer left'>
                    <p className='date'>9th May 2024</p>
                    <p className='update text'>Selectors Archive: Select Fables Series (S1/05)</p>
                    <a href='https://twitter.com/Selectors_/status/1758804143538352441?s=20' target='_blank'>
                        <img width='126' height='319' src={May9_2024} alt='may 9 2024'/>
                    </a>
                </div>
            </div>
        </div>
      </div>
  );
}

export default Timeline;
