import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../scss/index.scss';
import SelectorsOrange from '../assets/logos/selectorsOrange.webp';

import Cursor from '../components/cursor';
import Header from '../components/header';
import Navigation from '../components/navigation';
import Landing from '../components/landing';
import Banner from '../components/banner';
import HowItWorks from '../components/howItWorks';
import Benefits from '../components/benefits';
import Explore from '../components/explore';
import Archive from '../components/archive';
import FAQ from '../components/faq';
import Team from '../components/team';
import Whitelist from '../components/whitelist';
import Footer from '../components/footer';

import Timeline from '../pages/timeline';
import WhitelistPage from '../pages/whitelist';
import RoadmapPage from '../pages/roadmap';

const HomepageLayout = () => {
  return (
    <>
      <Header />
      <Navigation />
      <Landing />
      <Banner />
      <HowItWorks />
      <Benefits />
      <Explore />
      <Archive />
      <Team />
      <FAQ />
      <Whitelist />
      <Footer />
    </>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Homepage = () => {
  return (
    <div>
      <Helmet>
        <link rel="icon" href={SelectorsOrange} />
      </Helmet>
      <Cursor />
      <Router>
        <ScrollToTop /> {/* This ensures scrolling for all routes */}
        <Routes>
          <Route path="/" element={<HomepageLayout />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/whitelist" element={<WhitelistPage />} />
          <Route path="/roadmap" element={<RoadmapPage />} />
        </Routes>
      </Router>
    </div>
  );
};

const homepageElement = document.getElementById('homepage');
const homepage = createRoot(homepageElement);
homepage.render(<Homepage />);
