import React, { useState, useEffect } from 'react';
import Graffiti from '../assets/imagery/graffiti.webp';
import GraffitiMobile from '../assets/imagery/graffitiMobile.webp';

function Banner() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const graffitiSrc = windowWidth <= 1000 ? GraffitiMobile : Graffiti;

  return (
    <div className='banner' id='introStatementComponent'>
      <div className='left'>
        <div className='grafContainer'>
          <img width="300" height="178" src={graffitiSrc} className='graffitiOne' alt='graffiti image'/>
        </div>

        <div className='gradientTop'></div>
        <div className='gradientBottom'></div>
      </div>
      <div className='right'>
        <div>
          <div className='graffitiContainer'>
            <div className='sloganContainer'>
              <p>who are we</p>
            </div>
          </div>
          <div className='textContainer'>
            <p className='text'>Selectors is a music focused, lifestyle-based project founded on the love of discovering, sharing and collecting art and music. Everything we do is crafted with love and care for the people in our community.</p>
            <p className='text'>Our goal is simple, to release exclusive and unique experiences and products on a seasonal basis, that make the act of exploring and discovering art/ music a more accessible and exciting experience. It is home to the explorer and music lover.</p>
            <p className='text'>At Selectors we also believe that life is best lived when you experience it with others. Our vision is to build a place that houses a community of likeminded people and encourages them to connect, collaborate and fuel their passion for exploration through our unique offerings.</p>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default Banner;