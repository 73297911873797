import React, { useState, useEffect } from 'react';

function Archive() {
  return (
    <div className='archive' id='archiveComponent'>
      <p className='title'>The <span>Archive</span></p>
      <div className='pageContainer'>
        <div className='left'>
          <p className='description text'>We believe that music is something that should be enjoyed for free. Thats why, alongside the Selector Seasons we will also be building up the Selectors Archive, free for anyone to access through our various streaming platforms.</p>
          <br></br>
          <p className='description text'>You can check out our first curated playlists (on Spotify) and DJ mixes (on SoundCloud). More coming very soon.</p>
        </div>
        <div className='pointsContainer'>
          <div className='row'>
            <div className='point'>
              <div className='circle'></div>
              <p className='text'>Curated playlists from professionals in the game.</p>
            </div>
          </div>
          <div className='row'>
            <div className='point'>
              <div className='circle'></div>
              <p className='text'>DJ mixes.</p>
            </div>
          </div>
          <div className='row'>
          <div className='point'>
              <div className='circle'></div>
              <p className='text'>Selected fables series.</p>
            </div>
          </div>
          <div className='row'>
          <div className='point'>
              <div className='circle'></div>
              <p className='text'>Radio shows and more...</p>
            </div>
          </div>

        </div>
      </div>
      
      <div className='container'>
        <div>
            <iframe title='spotify' className='desktop' width="100%" height="360" src="https://open.spotify.com/embed/playlist/37vLfMiKQfEm9nEW9zh2Jy?utm_source=generator" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            <iframe title='spotifyMobile'className='mobile' src="https://open.spotify.com/embed/playlist/37vLfMiKQfEm9nEW9zh2Jy?utm_source=generator" width="100%" height="152" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>        
        </div>
        <div>
            <iframe title='soundcloud' className='desktop' width="100%" height="360" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1645470258&color=%23282828&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
            <iframe title='soundcloudMobile' className='mobile' width="100%" height="152" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1645470258&color=%23282828&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
        </div>
      </div>
    </div>
  );
}

export default Archive;
