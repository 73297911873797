import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Billboard from '../assets/imagery/billboard.webp';

function Whitelist() {
  return (
    <div className='whitelist'>
      <div className='imageContainer'>
          <img className='billboard' src={Billboard} alt='billboard image' width="593" height="410"/>
      </div>
      <div className='rightContainer'>
        <div>
          <div className='textContainer text'>
            <p className='heading'>Congratulations!</p>
            <p>You have reached the end of the road.<br></br>To celebrate we are giving away 20 Selectors members cards.<br></br>Follow us on X and enter your handle below to enter the giveaway. </p>
          </div>
          <div className='linkContainer'>
            <Link to='/whitelist' className='link'>
              <div className='claimContainer'>
                <p className='text'>Enter</p>
              </div>
            </Link>
            <p>Limited to 100 entries.<br></br>Winners announced after entries are filled.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whitelist;
