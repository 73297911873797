import React, { useState, useEffect } from 'react';
import SelectorsWhite from '../assets/logos/selectorsWhite.webp';

function Whitelist() {
  const [twitterUsername, setTwitterUsername] = useState('@');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      const timeoutId = setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [isSubmitted]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const cleanedUsername = twitterUsername.substring(1);

    if (cleanedUsername.length === 0) {
      return;
    }

    fetch('https://ps9p6kmg.api.sanity.io/v1/data/mutate/production', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer skczHsHfHphhv6xVFM4g6RePtYObxJ0mUL3X53DFBUuFpQqDWgEY62YTCuthSvtRfgBg3svZgYmvB1JfAfDuozZUY0rFs2pr5qSqwwjde0kNmz5pFBYRdQBf2dkW56ycmDE5UgFhfSxwgCWryFyu8nN3KYlckyvPplYZxj69eeU5qyBdsr20',
      },
      body: JSON.stringify({
        mutations: [
          {
            create: {
              _type: 'twitterSubmission', 
              name: twitterUsername,
            },
          },
        ],
      }),
    })
      .then((response) => {
        if (response.ok) {
          console.log('Data sent to Contentful successfully');
          setTwitterUsername('@');
          setIsSubmitted(true);
        } else {
          console.error('Error sending data to Contentful');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="whitelistPage">
      <div className="header">
        <div className="headerLeft">
          <a href="/" className="logoContainer" aria-label="Redirect back to the root of the site">
            <img width='50' height='50' className="logo" src={SelectorsWhite} alt="selectors logo" />
          </a>
        </div>
      </div>

      <div className="formContainer">
        <form onSubmit={handleSubmit}>
          <div className="inputContainer">
            <p>Twitter(X) username:</p>
            <input
              type="text"
              value={twitterUsername}
              aria-label='twitter username'
              onChange={(e) => {
                if (e.target.value.startsWith('@')) {
                  setTwitterUsername(e.target.value);
                } else if (e.target.value.length === 0) {
                  setTwitterUsername('@');
                }
              }}
            />
          </div>
          <button type="submit" className={isSubmitted ? 'disabled-button' : ''} disabled={isSubmitted}>
            Submit
          </button>
        </form>

        {isSubmitted && <div className="success-banner">Form submitted successfully!</div>}
      </div>
    </div>
  );
}

export default Whitelist;
