import React, { useState, useEffect } from 'react';
import vinyl from '../assets/vinyl.png';
import mag from '../assets/mag.png';
import tshirts from '../assets/tshirts.png';

const syncPointer = ({ x: pointerX, y: pointerY }) => {
  const x = pointerX.toFixed(2);
  const y = pointerY.toFixed(2);
  const xp = (pointerX / window.innerWidth).toFixed(2);
  const yp = (pointerY / window.innerHeight).toFixed(2);

  document.documentElement.style.setProperty('--x', x);
  document.documentElement.style.setProperty('--xp', xp);
  document.documentElement.style.setProperty('--y', y);
  document.documentElement.style.setProperty('--yp', yp);
};

function Benefits() {
  useEffect(() => {
    document.body.addEventListener('pointermove', syncPointer);

    // Cleanup the event listener on component unmount
    return () => {
      document.body.removeEventListener('pointermove', syncPointer);
    };
  }, []);
  return (
    <div className='benefits' id='benefitsComponent'>
        <p className='title'>WHAT IS THE FIRST SEASON LOOKING LIKE THEN?</p>
        <p className='description text'>For every season we will tell you exactly what we will be bringing, this way, you know exactly what your in for. Each season will differ to the next, bringing it's own fresh and unique ingredients, however, we would like our community to have a say in the direction and evolution of our seasons. The first season will consist of one fixed perk (Select Mag) and one random perk (Garment Token or NFT Record).</p>
        <div className='benefitsContainer'>
            <div className='fixedPerk'>
              <div className='benefit'>
                <div className='titleContainer'>
                    <p>SELECT MAG</p>
                    <div className='underline'></div>
                  </div>
                <img src={mag} className='mag'/>
                <div className='hover'>
                  <p className='content text'>The Select Mag is made exclusively for our community. A bit like a fanzine. These will be roundups of the season, Insights into our community, music and more. True collectible items.</p>
              </div>
              </div>
            </div>
            <div className='randomPerk'>
              <div className='container'>
                <div className='benefit'>
                  <div className='titleContainer'>
                    <p>EXCLUSIVE NFT RECORDS/MIXTAPE</p>
                    <div className='underline'></div>
                  </div>
                  <img src={vinyl} className='vinyl'/>
                  <div className='hover'>
                    <p className='content text'>More information coming soon...</p>
                  </div>
                </div>
                <div className='benefit'>
                  <div className='titleContainer'>
                    <p>GARMENT TOKEN</p>
                    <div className='underline'></div>
                  </div>
                  <img src={tshirts} className='tshirts'/>
                  <div className='hover'>
                    <p className='content text'>You can use one of these tokens to get full clothing items from our own clothing line or collaboration drops that we will be bringing in the first season.</p>
                    <p className='content text'>This isn't just your typical merch either, we take pride in making sure that we bring high quality, authentic and original designs and products.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='other'>
              <div className='benefit'>
                  <div className='titleContainer'>
                    <p>OTHER</p>
                    <div className='underline'></div>
                  </div>
              <div className='container'>
                  <div>
                    <div className='point'>
                      <div className='circle'></div>
                      <p className='content text'>Access to a platform looking to support independent creatives and create opportunities. From collaborating with us on a Selectors season or distributing some of your work independently through our platform. We are here to assist our community however we can.</p>
                    </div>
                  </div>
                  <div>
                    <div className='point'>
                      <div className='circle'></div>
                      <p className='content text'>Special giveaways - Both IRL and digital.</p>
                    </div>
                    <div className='point'>
                      <div className='circle'></div>
                      <p className='content text'>Access to a network of creative individuals looking to help each other grow.</p>
                    </div>
                  </div>
              </div>
              </div>
            </div>
        </div>
    </div>
  );
}

export default Benefits;
