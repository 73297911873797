import React, { useState, useEffect } from 'react';

function Navigation() {
  const [currentSection, setCurrentSection] = useState(null);
  const [shouldFadeOut, setShouldFadeOut] = useState(false);
  const [shouldFadeIn, setShouldFadeIn] = useState(false);

  useEffect(() => {
    let scrollTimer;
    var timer = null;

    const handleScroll = () => {
      const sectionIDs = [
        'landingComponent',
        'introStatementComponent',
        'howItWorksComponent',
        'benefitsComponent',
        'collectionComponent',
        'archiveComponent',
        'faqComponent',
        'teamComponent',
      ];
    
      let foundActiveSection = false;
    
      for (let i = sectionIDs.length - 1; i >= 0; i--) {
        const section = sectionIDs[i];
        const sectionComponent = document.getElementById(section);
    
        if (sectionComponent) {
          const boundingBox = sectionComponent.getBoundingClientRect();
          const { top, bottom } = boundingBox;
          const sectionName = section.replace('Component', '');
    
          const isSectionActive = top >= 0 && top <= window.innerHeight && bottom >= 0 && bottom <= window.innerHeight;
    
          if (isSectionActive) {
            setCurrentSection(sectionName);
            foundActiveSection = true;
          }
    
          // Toggle the active class for the current section and all sections above it
          document.getElementById(section).classList.toggle('active', foundActiveSection);
        }
      }  
          
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;

      if (scrollPosition >= viewportHeight * 0 && scrollPosition < viewportHeight - 86 - 72) {
        setShouldFadeOut(true);
      } else if (scrollPosition >= viewportHeight - 86) {
        setShouldFadeOut(false);
        setShouldFadeIn(true);
      } else {
        setShouldFadeOut(false);
        setShouldFadeIn(false);
      }

      if(timer !== null) {
        clearTimeout(timer);        
      }
      timer = setTimeout(function() {
        const sections = document.getElementsByClassName("section");
        const nav = document.getElementById("nav")
        for (const section of sections) {
          section.style.opacity = '0';
          nav.style.background = 'linear-gradient(90deg, rgba(255,255,255,0) 10%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 75%, rgba(255,255,255,0) 100%)';
          nav.style.pointerEvents = 'none';
        }

      }, 800);
      clearTimeout(scrollTimer);
      const sections = document.getElementsByClassName("section");
      for (const section of sections) {
        section.style.opacity = '1';
        nav.style.background = 'linear-gradient(90deg, rgba(255,255,255,0) 10%, rgba(255,255,255,0.15) 25%, rgba(255,255,255,0.25) 50%, rgba(255,255,255,0.45) 75%, rgba(255,255,255,.6) 100%)';
        nav.style.pointerEvents = 'auto';

      }

    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimer);
    };
  }, []);

  return (
    <div className={`navigation ${shouldFadeOut ? 'fade-out' : ''} ${shouldFadeIn ? 'fade-in' : ''}`} id='nav'>
      <div className='navigationContainer'>
        <div className={`section ${currentSection === 'introStatement' ? 'active' : ''}`} id='introStatement'>
          <a href='#introStatementComponent' onClick={(e) => scrollToSection(e, 'introStatementComponent')} style={{ opacity: currentSection === 'introStatement' ? 1 : 0.17, transform: currentSection === 'introStatement' ? 'scale(1,1)' : 'scale(1)' }}>
            Intro statement
          </a>
          <div className='activeIndicatior'></div>
        </div>
        <div className={`section ${currentSection === 'howItWorks' ? 'active' : ''}`} id='howItWorks'>
          <a href='#howItWorksComponent' onClick={(e) => scrollToSection(e, 'howItWorksComponent')} style={{ opacity: currentSection === 'howItWorks' ? 1 : 0.17, transform: currentSection === 'howItWorks' ? 'scale(1,1)' : 'scale(1)'  }}>
            How it works
          </a>
          <div className='activeIndicatior'></div>
        </div>
        <div className={`section ${currentSection === 'benefits' ? 'active' : ''}`} id='benefits'>
          <a href='#benefitsComponent' onClick={(e) => scrollToSection(e, 'benefitsComponent')} style={{ opacity: currentSection === 'benefits' ? 1 : 0.17, transform: currentSection === 'benefits' ? 'scale(1,1)' : 'scale(1)'  }}>
            Benefits
          </a>
          <div className='activeIndicatior'></div>
        </div>
        <div className={`section ${currentSection === 'collection' ? 'active' : ''}`} id='collection'>
          <a href='#collectionComponent' onClick={(e) => scrollToSection(e, 'collectionComponent')} style={{ opacity: currentSection === 'collection' ? 1 : 0.17, transform: currentSection === 'collection' ? 'scale(1,1)' : 'scale(1)'  }}>
            The collection
          </a>
          <div className='activeIndicatior'></div>
        </div>
        <div className={`section ${currentSection === 'archive' ? 'active' : ''}`} id='archive'>
          <a href='#archiveComponent' onClick={(e) => scrollToSection(e, 'archiveComponent')} style={{ opacity: currentSection === 'archive' ? 1 : 0.17, transform: currentSection === 'archive' ? 'scale(1,1)' : 'scale(1)'  }}>
            Archive
          </a>
          <div className='activeIndicatior'></div>
        </div>
        <div className={`section ${currentSection === 'faq' ? 'active' : ''}`} id='faq'>
          <a href='#faqComponent' onClick={(e) => scrollToSection(e, 'faqComponent')} style={{ opacity: currentSection === 'faq' ? 1 : 0.17, transform: currentSection === 'faq' ? 'scale(1,1)' : 'scale(1)'  }}>
            FAQ
          </a>
          <div className='activeIndicatior'></div>
        </div>
        <div className={`section ${currentSection === 'team' ? 'active' : ''}`} id='team'>
          <a href='#teamComponent' onClick={(e) => scrollToSection(e, 'teamComponent')} style={{ opacity: currentSection === 'team' ? 1 : 0.17, transform: currentSection === 'team' ? 'scale(1,1)' : 'scale(1)'  }}>
            Team
          </a>
          <div className='activeIndicatior'></div>
        </div>
      </div>
    </div>
  );
}

function scrollToSection(event, sectionId) {
  event.preventDefault();
  const target = document.getElementById(sectionId);
  if (target) {
    window.scrollTo({
      top: target.offsetTop - 85,
      behavior: 'smooth',
    });
  }
}

export default Navigation;
